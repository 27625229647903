import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import ModalSimple from '@solid-ui-blocks/Modal/Block02'
import Header from '@solid-ui-blocks/Header/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Testimonials from '@solid-ui-blocks/Testimonials/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import styles from './_styles'

const Terms = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout {...props}>
      <Seo title='About Us' />
      {/* Modals */}
      <ModalWithTabs content={content['contact']} />
      {/* Blocks */}
      <Header content={content['header']} />
      <Divider space='5' />
      <p className='text-center text-xl font-bold'>
        Terms & Conditions
      </p>
      <br/>
      <p style={styles.policyHeader}>
        Cancellation Policy
      </p>
      <p style={styles.policyParagraph}>
        If 24 hours’ notice is not provided to Budding Superstars, the tutorial is chargeable at the full rate.  We do not offer any refunds for missed tutorials.
      </p>
      <p style={styles.policyHeader}>
        Tutor Recruitment
      </p>
      <p style={styles.policyParagraph}>
        We are always happy to hear from prospective tutors. If you think you would like to join our team please get in touch with us at <a href="mailto:info@buddingsuperstars.com"><u>info@buddingsuperstars.com</u></a>.  Please kindly note that tutors must have a qualification in the recognized area and must also have a PVG. 
      </p>


      <Divider space='4' />
      <Footer content={content['footer']} />
    </Layout>
  )
}

export const query = graphql`
  query innerpageTermsBlockContent {
    allBlockContent(
      filter: { page: { in: ["site/terms-and-conditions", "shared"] } }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`

export default Terms

