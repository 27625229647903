import React from 'react'
import { Container, Flex, Box, css } from 'theme-ui'
import Reveal from '@solid-ui-components/Reveal'
import Divider from '@solid-ui-components/Divider'
import ContentText from '@solid-ui-components/ContentText'
import ContentImages from '@solid-ui-components/ContentImages'
import ContentContainer from '@solid-ui-components/ContentContainer'
import ContentButtons from '@solid-ui-components/ContentButtons'
import { ImQuotesRight } from 'react-icons/im'
import { AiFillStar } from 'react-icons/ai'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  avatar: {
    size: 120,
    display: `block`,
    bg: `omegaLighter`,
    borderTopColor: `omegaLighter`,
    borderTopWidth: `lg`,
    borderTopStyle: `solid`,
    borderRadius: `full`,
    boxSizing: `content-box`,
    mx: `auto`,
    mt: -90,
    mb: 3
  }
}

const TestimonialsBlock01 = ({ content: { text, collection, buttons }, customWidth, customHeight }) => (
  <Container>
    <Box sx={{ textAlign: `center`, width: `100%`}}>
      <ContentText content={text} />
    </Box>
    <Divider />
    <Divider />
    <Flex sx={{ mx: -3, flexWrap: `wrap`, marginBottom: `-5%` }}>
      {collection?.map(({ container, avatar, text, buttons }, index) => (
        <Box
          key={`item-${index}`}
          sx={{ flexBasis: [`1`, `1/2`, `1/3`, `1/4`], flexGrow: 1, p: 3 }}
        >
          <Reveal effect='fadeInLeft' delay={0.25 * (index + 2)}>
            <ContentContainer
              content={container}
              variant='cards.paper'
              sx={{
                textAlign: `center`,
                width: customWidth ? `100%` : `50%`,
                minWidth: `250px`,
                minHeight: customHeight ? customHeight : null,
                margin: `auto`,
                marginBottom: `5%`
              }}
            >
              <ContentImages
                content={{ images: [avatar] }}
                sx={styles.avatar}
                objectPosition='top center'
                imageEffect='fadeIn'
              />
              <ContentText content={text} />
              {buttons && (
                <Reveal
                  effect='fadeInRight'
                  delay={1}
                  css={css({ mb: [4, null, null, 0] })}
                >
                  {buttons && (
                    <>
                      <Divider space={3} />
                      <ContentButtons content={buttons} />
                    </>
                  )}
                </Reveal>
              )}
            </ContentContainer>
          </Reveal>
        </Box>
      ))}
    </Flex>
  </Container>
)

export default WithDefaultContent(TestimonialsBlock01)
